import { useData } from '../store'
import { Action, ClientResponse } from '../../type/Common'
import { API_URL_COMPANY_CREATE_CLIENT, API_URL_LIST_ACCOUNT_MANAGERS } from '../../utilities/APIConstant'
import { ClientSearchType } from '../../type/ClientType'
import { useClient } from '@core/hooks/useClient'

const CLIENT_DATA_CHANGE = 'CLIENT_DATA_CHANGE'

export const ClientReducer = (state: any = { readyForNext: false, loadingJob: true, firstLoadSuccess: false }, action: Action): any => {
  switch (action.type) {
    case CLIENT_DATA_CHANGE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}

export const useClientData = (): {
  companyClient: any
  createClient: (data) => Promise<ClientResponse>
  getClients: (searchValue: ClientSearchType) => Promise<ClientResponse>
  getListAccountManager: () => Promise<ClientResponse>
} => {
  // todo: Refactoring code below for readability
  // separate concern on paging and job fetching
  const { state } = useData()
  const { client } = useClient()
  const companyClient = state?.clientCompany

  const getClients = (searchValue) => {
    return client.get(API_URL_COMPANY_CREATE_CLIENT, { params: searchValue })
  }

  const createClient = (data) => {
    return client.post(API_URL_COMPANY_CREATE_CLIENT, { data })
  }

  const getListAccountManager = () => {
    return client.get(API_URL_LIST_ACCOUNT_MANAGERS, {})
  }

  return {
    companyClient,
    createClient,
    getClients,
    getListAccountManager
  }
}
