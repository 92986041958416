import { useData } from './store'
import { Action, JobLevelType, SkillType, LanguageType, FastJsonList, LanguagesProficiencyType, SkillYearType } from '../type/Common'
import { useEffect } from 'react'
import { API_URL_SKILLS, API_CANDICATE_PROFILE } from '../utilities/APIConstant'
import { AxiosResponse } from 'axios'
import { skill_years } from './utilities'
import {
  CandidateProfileSerializer,
  ProfileType,
  WorkExperienceType,
  EducationType,
  ProfileSkillType,
  ProfileLanguageType,
  CertificateProfileType,
  WorkReferenceType
} from '../type/CandidateProfile'
import { LanguagesProficiencys, languageList } from '../utilities/common'
import { dayjs } from '@core/utilities/dayjs'
import { useClient } from '@core/hooks/useClient'
const PROFILE_STATE_CHANGE = 'PROFILE_STATE_CHANGE'

export type CandidateProfileDataType = {
  profile?: CandidateProfileSerializer
  skills?: SkillType[]
  job_levels?: JobLevelType[]
  languages?: LanguageType[]
}
export function getDefaultCandidateProfile(): CandidateProfileDataType {
  return {}
}
export const CandidateProfileReducer = (
  state: CandidateProfileDataType = getDefaultCandidateProfile(),
  action: Action
): CandidateProfileDataType => {
  switch (action.type) {
    case PROFILE_STATE_CHANGE: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}
export type UpdatePersonalType = {
  level: number
  job_title: string
  about: string
  phone_number: number
  full_name: string
  photo_id: number
  dob: string
  location_id: number
}
export function useCandidateProfileData(): {
  profile?: CandidateProfileSerializer
  skills: SkillType[]
  skill_years: SkillYearType[]
  languages: LanguageType[]
  languagesProficiencys: LanguagesProficiencyType[]
  fetchProfile: () => Promise<void>
  updateProfile: (profile: ProfileType) => Promise<any>
  updatePersional: (data: UpdatePersonalType) => Promise<any>
  updateEducation: (listEducations: EducationType[]) => Promise<any>
  updateWorkExperience: (listWorkexperience: WorkExperienceType[]) => Promise<any>
  updateSkills: (listSkills: ProfileSkillType[]) => Promise<any>
  updateLanguages: (listSkills: ProfileLanguageType[]) => Promise<any>
  updateCertificates: (listCertificate: CertificateProfileType[]) => Promise<any>
  updateWorkReference: (listWorkReference: WorkReferenceType[]) => Promise<any>
} {
  const { state, dispatch } = useData()
  const candidateProfile = state.candidateProfile
  const { client } = useClient()
  const fetchProfile = () =>
    client.get(API_CANDICATE_PROFILE, {}).then((res: AxiosResponse<CandidateProfileSerializer>) => {
      const listorderedWorkExperience = (res?.data?.data?.attributes?.work_experiences?.data || []).sort((a, b) =>
        dayjs(b.attributes.to).diff(dayjs(a.attributes.to), 'minutes')
      )
      const listorderedEducation = (res?.data?.data?.attributes?.educations?.data || []).sort((a, b) =>
        dayjs(b.attributes.to).diff(dayjs(a.attributes.to), 'minutes')
      )
      const listorderedCertificate = (res?.data?.data?.attributes?.certificates?.data || []).sort((a, b) =>
        dayjs(b.attributes.date).diff(dayjs(a.attributes.date), 'minutes')
      )
      dispatch({
        type: PROFILE_STATE_CHANGE,
        payload: {
          profile: {
            ...res.data,
            attributes: {
              ...res.data.data.attributes,
              work_experiences: listorderedWorkExperience,
              educations: listorderedEducation,
              certificates: listorderedCertificate
            }
          }
        }
      })
    })

  const updatePersional = (data: UpdatePersonalType) =>
    client.put(`/api/v2/candidates/profile/personal`, { data }).then(() => fetchProfile())

  const updateProfile = (profile: ProfileType) => client.post(API_CANDICATE_PROFILE, { data: profile }).then(() => fetchProfile())

  const updateWorkExperience = (listWorkexperience: WorkExperienceType[]) =>
    client
      .put(`/api/v2/candidates/profile/work-experiences`, { data: { profile_work_experiences_attributes: listWorkexperience } })
      .then(() => fetchProfile())

  const updateEducation = (listEducations: EducationType[]) =>
    client
      .put(`/api/v2/candidates/profile/educations`, { data: { profile_educations_attributes: listEducations } })
      .then(() => fetchProfile())

  const updateSkills = (listSkills: ProfileSkillType[]) =>
    client.put(`/api/v2/candidates/profile/skills`, { data: { profile_skills_attributes: listSkills } }).then(() => fetchProfile())

  const updateLanguages = (listLanguages: ProfileLanguageType[]) =>
    client.put(`/api/v2/candidates/profile/languages`, { data: { profile_languages_attributes: listLanguages } }).then(() => fetchProfile())

  const updateCertificates = (listCertificate: CertificateProfileType[]) =>
    client
      .put(`/api/v2/candidates/profile/certificates`, { data: { profile_certificates_attributes: listCertificate } })
      .then(() => fetchProfile())

  const updateWorkReference = (listWorkReference: WorkReferenceType[]) =>
    client
      .put(`/api/v2/candidates/profile/work-references`, { data: { profile_work_references_attributes: listWorkReference } })
      .then(() => fetchProfile())

  useEffect(() => {
    if (!candidateProfile.skills) {
      client.get(API_URL_SKILLS, {}).then((res: AxiosResponse<FastJsonList<SkillType>>) => {
        dispatch({ type: PROFILE_STATE_CHANGE, payload: { skills: res.data.data.map((ser) => ser.attributes) } })
      })
    }
  }, [])
  return {
    profile: candidateProfile.profile,
    skills: candidateProfile.skills,
    languages: languageList,
    languagesProficiencys: LanguagesProficiencys,
    skill_years: skill_years,
    fetchProfile,
    updateProfile,
    updatePersional,
    updateWorkExperience,
    updateEducation,
    updateSkills,
    updateLanguages,
    updateCertificates,
    updateWorkReference
  }
}
