import React from 'react'
import { Trans } from 'react-i18next'
import { LINK_URL_EMPLOYER_CONTACT } from '../utilities/LinkURL'
import { Action } from '../type/Common'
import { useAppRouter } from '@core/hooks/UseAppRouter'
const LP_STATE_CHANGE = 'LP_STATE_CHANGE'

interface ListParners {
  id: number
  path: string
  link: string
}
interface ListMentioned {
  id: number
  path: string
  link: string
  width: number
  height: number
}
interface listClients {
  id: number
  path: string
  content: string
  name: string
  position: string
}

interface listQuestions {
  id: string
  title: any
  content: any
}
export type LPType = {}

export const LPReducer = (state = {}, action: Action): LPType => {
  switch (action.type) {
    case LP_STATE_CHANGE: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}
export const useLPCompanyData = (): {
  listPartners: Array<ListParners>
  listMentioned: Array<ListMentioned>
  listClients: Array<listClients>
  listQuestions: Array<listQuestions>
} => {
  const router = useAppRouter()

  const listPartners = [
    { id: 1, path: '/img/imgLP/partner1.png', link: 'https://line.me/vi/' },
    { id: 2, path: '/img/imgLP/partner2.png', link: 'https://pops.vn/' },
    { id: 3, path: '/img/imgLP/partner3.png', link: `${process.env.NEXT_PUBLIC_APP_DOMAIN}/company/sun-inc-6420-fi5807/jobs` },
    { id: 4, path: '/img/imgLP/partner4.png', link: 'https://www.techbasevn.com/' },
    { id: 5, path: '/img/imgLP/partner5.png', link: 'https://www.tek-experts.com/vi-vn' },
    { id: 6, path: '/img/imgLP/partner6.jpg', link: 'https://realmemobile.vn/' },
    { id: 7, path: '/img/imgLP/partner7.jpg', link: 'https://www.beiersdorf.vn/' },
    { id: 8, path: '/img/imgLP/partner8.png', link: 'https://jiohealth.com/' },
    {
      id: 9,
      path: '/img/imgLP/partner9.png',
      link: `${process.env.NEXT_PUBLIC_APP_DOMAIN}/company/vus--anh-van-hoi-viet-my-7564-fi6926/jobs`
    },
    { id: 10, path: '/img/imgLP/partner10.png', link: 'https://www.brother.com.vn/vi-vn' }
  ]
  const listMentioned = [
    {
      id: 1,
      path: '/img/imgLP/new_1.png',
      link: 'https://www.brandsvietnam.com/20570-2-quy-tac-san-dau-nguoi-khac-biet-cua-nen-tang-cong-nghe-tuyen-dung-kieu-Nhat',
      width: 190,
      height: 58
    },
    {
      id: 2,
      path: '/img/imgLP/new_2.png',
      link: 'https://dantri.com.vn/an-sinh/diem-danh-5-website-tuyen-dung-viec-lam-uy-tin-hieu-qua-hang-dau-nam-2020-20200207110911233.htm',
      width: 190,
      height: 46
    },
    {
      id: 3,
      path: '/img/imgLP/new_3.png',
      link: 'https://baodautu.vn/freec-ra-mat-chuyen-trang-moi-danh-rieng-cho-nha-tuyen-dung-d124280.html',
      width: 190,
      height: 51
    },
    {
      id: 4,
      path: '/img/imgLP/new_4.png',
      link: 'https://startup.vnexpress.net/tin-tuc/hanh-trinh-khoi-nghiep/tham-vong-cua-freec-khi-hop-tac-persol-asia-pacific-4103743.html',
      width: 190,
      height: 66
    }
  ]
  const listClients = [
    {
      id: 1,
      path: '/img/imgLP/avatar_1.jpg',
      content:
        'We ask recruiting service for engineers and designers to freeC. Not only they recommend us very quick due to platform and AI, but also their consultants support our HR to connect candidates. And freeC create the best path for both companies and candidate.',
      name: 'Mr. Yoshimasa Fukuda',
      position: 'CEO at ORO'
    },
    {
      id: 2,
      path: '/img/imgLP/avatar_2.jpg',
      content: 'Quick response to job orders. We can receive high quality candidates and CVs in average 3 days with detailed information.',
      name: 'Mr. Ken Vo',
      position: 'CEO Vietnam at LastMile'
    },
    {
      id: 3,
      path: '/img/imgLP/avatar_3.png',
      content:
        'freeC is totally new and advanced version of Recruiting Business. \nTheir unique model using big platform, AI, and consultants will be standard in future recruiting. \nAs a tech company, we realize we need to utilize advanced tool for recruiting.',
      name: 'Mr. Shunsuke Fujita',
      position: 'CEO at Lifull'
    },
    {
      id: 4,
      path: '/img/imgLP/avatar_4.jpg',
      content: "FreeC's consultant supported our company to find the best fit for our jobs very fast and get closer to candidates.",
      name: 'Ms. Vo Kim Ngan',
      position: 'HR & Administration at KDDI'
    }
  ]
  const listQuestions = [
    {
      id: '1',
      title: <Trans i18nKey="page_employer_headhunt_service:lp_how_much_is_your_service_fee" />,
      content: (
        <>
          <Trans i18nKey="page_employer_headhunt_service:lp_we_charge_approximately">
            <button onClick={() => router.push(LINK_URL_EMPLOYER_CONTACT)} className="highlight btn-open-modal"></button>
          </Trans>
        </>
      )
    },
    {
      id: '2',
      title: <Trans i18nKey="page_employer_headhunt_service:lp_how_you__guarantee" />,
      content: <Trans i18nKey="page_employer_headhunt_service:lp_if_candidates_stop_working" />
    },
    {
      id: '3',
      title: <Trans i18nKey="page_employer_headhunt_service:lp_what_is_your_uniqueness" />,
      content: (
        <>
          <Trans i18nKey="page_employer_headhunt_service:lp_we_are_hrtech_company">
            <button onClick={() => router.push(LINK_URL_EMPLOYER_CONTACT)} className="highlight btn-open-modal"></button>
          </Trans>
        </>
      )
    },
    {
      id: '4',
      title: <Trans i18nKey="page_employer_headhunt_service:lp_how_long_does_it_take_to_get_first_cv" />,
      content: <Trans i18nKey="page_employer_headhunt_service:lp_it_depends_on_position" />
    },
    {
      id: '5',
      title: <Trans i18nKey="page_employer_headhunt_service:lp_is_freec_new_player" />,
      content: <Trans i18nKey="page_employer_headhunt_service:lp_yes_we_came_to_vn_market" />
    },
    {
      id: '6',
      title: <Trans i18nKey="page_employer_headhunt_service:lp_where_is_focus_area_of_freec" />,
      content: <Trans i18nKey="page_employer_headhunt_service:lp_we_are_strong_at_IT_field" />
    }
  ]
  return {
    listPartners,
    listMentioned,
    listClients,
    listQuestions
  }
}
