export const LINK_URL_JOBS = '/jobs'
export const LINK_URL_FORGOT_PASSWORD = '/forgot-password'
export const LINK_URL_LOGIN = '/login'
export const LINK_URL_ROOT = '/'

export const LINK_URL_COMPANY_JOB_LIST = (companyID: number): string => `/employer/${companyID}/jobs`
export const LINK_KEY_COMPANY_JOB_LIST = `/employer/[companyID]/jobs`
export const LINK_URL_COMPANY_EDIT_JOB = (companyID: number, id: number | string): string => `${LINK_URL_COMPANY_JOB_LIST(companyID)}/${id}`
export const LINK_KEY_COMPANY_EDIT_JOB = `${LINK_KEY_COMPANY_JOB_LIST}/[id]`
export const LINK_URL_COMPANY_JOB_DETAIL = (companyID: number, id: number | string): string =>
  `${LINK_URL_COMPANY_JOB_LIST(companyID)}/${id}/detail`

export const LINK_KEY_COMPANY_JOB_DETAIL = `${LINK_KEY_COMPANY_JOB_LIST}/[id]/detail`
export const LINK_URL_COMPANY_JOB_PREVIEW = (companyID: number, id: number | string): string => `/employer/${companyID}/${id}/preview`
export const LINK_URL_INVITE_MEMBER = '/account/invite-team-members'
export const LINK_URL_EMAIL_TEMPLATES = '/account/email-templates'

export const LINK_URL_EMPLOYER_CONTACT = '/employer/contact'
export const LINK_URL_CHAT_WITH_SUPPORT = '/chat-with-support'

export const LINK_URL_EMAIL_SENT = '/forgot-password/confirmation'
export const LINK_URL_FORGOT_VERIFY_TOKEN = '/forgot-password/reset-password'

export const LINK_JOB_POST_POLICY =
  process.env.NEXT_PUBLIC_LINK_JOB_POST_POLICY || 'https://support.freec.asia/vi/article/quy-dinh-dang-tin-1kpnrdd/?bust=1621588019101'

export const LINK_URL_MY_ACCOUNT = '/account/your-account'
export const LINK_URL_VERIFY_MEMBER_UPDATE_PROFILE_INFOR = '/verify-member/update-member-infor'
export const LINK_URL_TOKEN_NOT_FOUND = '/token-not-found'
export const LINK_URL_MY_JOBS = '/my-jobs'
export const LINK_URL_CANDIDATES_JOB_ALERT = '/account/job-alert'
export const LINK_URL_CANDIDATES_MY_PROFILE = '/my-profile'
export const LINK_URL_MY_JOBS_APPLIED = '/my-jobs/applied'
export const LINK_URL_MY_JOBS_SAVED = '/my-jobs/saved'
export const LINK_URL_CANDIDATES_CHANGE_PASSWORD = '/account/change-password'
export const LINK_URL_ACCOUNT_COMPANY = '/account/company-account'
export const LINK_URL_RECRUITER_RECREATE_JOBS = (comapyid: number, jobId: number | string): [string, string] => [
  `/employer/[companyID]/jobs/create?from-job-id=${jobId}`,
  `/employer/${comapyid}/jobs/create?from-job-id=${jobId}`
]
export const LINK_URL_COMPANY_DETAIL = (slug: string) => `/company/${slug}/jobs`

export const LINK_URL_CONSULTANTS_CLIENT_ADD_AS_CLIENT = '/consultants/clients/add-as-client'
export const LINK_URL_CONSULTANTS_JOB_ORDERS = '/consultants/job-orders'
export const LINK_URL_CONSULTANTS_JOB_ORDERS_CREATE = '/consultants/job-orders/create'
export const LINK_URL_CONSULTANTS_JOBS = `/consultants/job-orders`
export const LINK_URL_CONSULTANTS_JOBS_EDIT = (jobId: number | string): string => `/consultants/job-orders/${jobId}/update`
export const LINK_KEY_CONSULTANTS_JOBS_EDIT = '/consultants/job-orders/[id]/update'
export const LINK_URL_CONSULTANT_RECREATE_JOBS = (id: number | string): string => `/consultants/job-orders/create?from-job-id=${id}`
export const LINK_URL_CONSULTANT_VIEW_PROFILE = (id: number | string): string => `/consultants/source/${id}/detail`

export const LINK_URL_CONSULTANT_VIEW_APPLICANT = (jobID: number | string, candidatesID: number | string): string =>
  `/consultants/job-orders/${jobID}/applicants/${candidatesID}`

export const LINK_URL_CONSULTANT_COMPANIES_DETAIL = (id: number | string | string[]): string => `/consultants/companies/${id}`
export const LINK_URL_ADMIN_COMPANIES_DETAIL = (id: number | string | string[]): string => `/companies/${id}`

export const LINK_URL_ADMIN_REVIEW_JOBS = '/review-jobs'
export const LINK_URL_ADMIN_COMPANY = '/companies'
export const LINK_URL_ADMIN_ADD_CLIENT = (company_id: number): string => `/companies/add-as-client/${company_id}`

export const LINK_URL_EDIT_BRANDED_PAGE = (id: number | string | string[]): string => `/company/${id}`
