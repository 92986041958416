import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { JobCategorySerializeType, NestedCategoryType } from '../type/Common'
import { API_URL_JOB_CATEGORIES } from '../utilities/APIConstant'
import client from '../utilities/Client'
import { createOneTimeRun } from '../utilities/SingleInvokeFuction'
import { createGlobalStage } from './share/GlobalStateHooks'
import { useTranslation } from './UserTranslation'

const [useJobCategoriesState] = createGlobalStage<NestedCategoryType[]>()
const oneTimeRun = createOneTimeRun()
export const useJobCategories = () => {
  const [job_categories, set_job_categories] = useJobCategoriesState()
  const [loading, setLoading] = useState<boolean>(false)
  const { currentLanguage } = useTranslation()
  useEffect(() => {
    oneTimeRun(() => {
      setLoading(true)
      return client
        .get(API_URL_JOB_CATEGORIES, {})
        .then((res: AxiosResponse<JobCategorySerializeType>) => {
          set_job_categories(
            res.data.data.map((ser) => ({
              ...ser.attributes,
              name: currentLanguage === 'en' ? ser.attributes.name : ser.attributes.name_vn,
              subs: ser.attributes.subs.data.map((ser2) => ({
                ...ser2.attributes,
                name: currentLanguage === 'en' ? ser2.attributes.name : ser2.attributes.name_vn
              }))
            }))
          )
        })
        .then(() => setLoading(false))
    })
  }, [])
  return { job_categories, loading }
}
