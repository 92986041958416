import React, { ReactElement, useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import { DataProvider } from '../store/store'
import { LayoutProvider } from '../layout/DefaultLayout'
import createI18n from '../locales/i18n'
import { ServerVarStoreType } from '@core/utilities/CollectServerVariableContent'
import { FreeCNextPage } from '../type/Common'
import { useNProgress } from '../hooks/NProgress'
import { getIsServer } from '../utilities/common'
import { getCookie } from '../utilities/Cookies'
import FreecProvider, { ServerBunble, ServerService } from '@core/components/FreecProvider'
import { useRouter } from 'next/router'

interface FreecAppProps extends AppProps {
  Component: FreeCNextPage
  serverVar: ServerVarStoreType
  cookieLanguage?: string
  serverGetCookie?: (key: string) => string
}

function FreecApp({
  Component,
  pageProps,
  serverVar,
  serverGetCookie,
  serverBundle,
  serverService
}: FreecAppProps & { serverService: ServerService; serverBundle: ServerBunble }): ReactElement {
  createI18n('en')
  useNProgress()
  const [clientRender, setClientRender] = useState(false)
  const router = useRouter()
  useEffect(() => {
    setClientRender(true)
  }, [])
  return (
    <FreecProvider serverBundle={serverBundle} serverService={serverService}>
      <DataProvider serverVar={serverVar} getCookie={getIsServer() ? serverGetCookie : getCookie}>
        <LayoutProvider>{clientRender && router.isReady ? <Component {...pageProps} /> : <div>loading...</div>}</LayoutProvider>
      </DataProvider>
    </FreecProvider>
  )
}

export default FreecApp
