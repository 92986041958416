import { useAppRouter } from '@core/hooks/UseAppRouter'
import { ColorsStyle } from '@core/style/base/colors.style'
import { FontsStyle } from '@core/style/base/fonts.style'
import { ResetStyle } from '@core/style/base/reset.style'
import { SidebarStyle } from '@core/style/layout/sidebar.style'
import { AntdStyle } from '@core/style/utilities/antd.style'
import { MixinsStyle } from '@core/style/utilities/mixins.style'
import { TextStyle } from '@core/style/utilities/text.style'
import { VariableStyle } from '@core/style/utilities/variable.style'
import { setOn401, setOn403, useRoutingCollector } from '@core/utilities/Client'
import Head from 'next/head'
import 'nprogress/nprogress.css'
import React, { FC, useEffect } from 'react'
import { useCollectHistory } from '../hooks/Common'
import { LINK_URL_LOGIN } from 'src/utilities/LinkURL'
const DefaultLayout: FC = ({ children }) => {
  const router = useAppRouter()
  useEffect(() => {
    setOn401(() => router.push(LINK_URL_LOGIN))
    setOn403(() => router.push(LINK_URL_LOGIN))
  }, [])

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/img/apple-touch-icon.png`} />
        {process.env.NEXT_PUBLIC_NO_INDEX === 'true' && <meta name="robots" content="noindex" />}
        <title>freeC - Website tuyển dụng thông minh</title>
        <link rel="shortcut icon" type="image/png" sizes="32x32" href="/favicon_32x32.png"></link>
        <link rel="shortcut icon" type="image/png" sizes="16x16" href="/favicon_16x16.png"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap"
          rel="stylesheet"></link>
        {process.env.GTM_KEY && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.GTM_KEY}');`
            }}
          />
        )}
      </Head>

      {process.env.GTM_KEY && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.GTM_KEY}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}></iframe>
        </noscript>
      )}
      {children}
    </>
  )
}

export const LayoutProvider: FC<{}> = ({ children }) => {
  const router = useAppRouter()
  useRoutingCollector(router)
  useCollectHistory()

  const style = (
    <>
      <style jsx global>
        {AntdStyle}
      </style>
      <style jsx global>
        {VariableStyle}
      </style>
      <style jsx global>
        {ResetStyle}
      </style>
      <style jsx global>
        {TextStyle}
      </style>
      <style jsx global>
        {FontsStyle}
      </style>
      <style jsx global>
        {ColorsStyle}
      </style>
      <style jsx global>
        {MixinsStyle}
      </style>
      <style jsx global>
        {SidebarStyle}
      </style>
    </>
  )

  return (
    <>
      {style}
      <DefaultLayout>{children}</DefaultLayout>
    </>
  )
}

export default DefaultLayout
