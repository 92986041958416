import { Action, FastJsonObject, JobType, FastJsonList } from '../type/Common'
import { useData } from './store'
import { JobStageType } from '../type/JobStageType.type'
import { API_URL_JOBS_CANDIDATES } from '../utilities/APIConstant'
import { AxiosResponse } from 'axios'
import { ApplicantProfileSerializer } from '../type/ApplicantType'
import { useClient } from '@core/hooks/useClient'

const CONSULTANT_JOB_DETAIL_DATA_CHANGE = 'CONSULTANT_JOB_DETAIL_DATA_CHANGE'
export type ConsultantJobDetailPage = {
  [id: string]: {
    jobDetail?: FastJsonObject<JobType>
    jobStage?: FastJsonList<JobStageType>
    candidateList?: FastJsonList<ApplicantProfileSerializer>
  }
}

export const ConsultantJobDetailReducer = (state: ConsultantJobDetailPage = {}, action: Action): ConsultantJobDetailPage => {
  switch (action.type) {
    case CONSULTANT_JOB_DETAIL_DATA_CHANGE: {
      const { id, payload } = action.payload
      const oldPayload = state[id] || {}
      return { ...state, [id]: { ...oldPayload, ...payload } }
    }
    default:
      return state
  }
}

/**
 * Hook
 */

export const useConsultantJobDetail = (
  jobId: string
): {
  jobDetail: FastJsonObject<JobType>
  setJobDetail: (id: string, jobDetail: FastJsonObject<JobType>) => void
  jobStage: FastJsonList<JobStageType>
  setJobStage: (id: string, jobDetail: FastJsonList<JobStageType>) => void
  candidateList: FastJsonList<ApplicantProfileSerializer>
  setCandidateList: (id: string, candidateList: FastJsonList<ApplicantProfileSerializer>) => void
  fetchJobApplicant: () => Promise<void>
} => {
  const { client } = useClient()
  const { state, dispatch } = useData()
  const consultantJobDetail = state?.consultantJobDetail
  const { jobDetail, jobStage, candidateList } = consultantJobDetail[jobId] || {}
  const setJobDetail = (id: string, jobDetail: FastJsonObject<JobType>) =>
    dispatch({ type: CONSULTANT_JOB_DETAIL_DATA_CHANGE, payload: { id, payload: { jobDetail } } })
  const setJobStage = (id: string, jobStage: FastJsonList<JobStageType>) =>
    dispatch({ type: CONSULTANT_JOB_DETAIL_DATA_CHANGE, payload: { id, payload: { jobStage } } })
  const setCandidateList = (id: string, candidateList: FastJsonList<ApplicantProfileSerializer>) =>
    dispatch({ type: CONSULTANT_JOB_DETAIL_DATA_CHANGE, payload: { id, payload: { candidateList } } })
  const fetchJobApplicant = () => {
    return client.get(API_URL_JOBS_CANDIDATES(jobId), {}).then((rs: AxiosResponse<FastJsonList<ApplicantProfileSerializer>>) => {
      setCandidateList(jobId, rs.data)
    })
  }
  return { jobDetail, setJobDetail, jobStage, setJobStage, candidateList, setCandidateList, fetchJobApplicant }
}
