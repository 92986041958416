import css from 'styled-jsx/css'

export const MixinsStyle = css.global`
  .relative {
    position: relative;
  }
  .d-flex {
    display: flex;
  }

  .flex-one {
    flex: 1;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-column {
    flex-direction: column;
  }

  .align-items-center {
    align-items: center;
  }

  .justify-content-space-between {
    justify-content: space-between;
  }

  .justify-content-space-around {
    justify-content: space-around;
  }

  .justify-content-end,
  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  body .mb-2 {
    margin-bottom: 12px;
  }

  body .mb-3 {
    margin-bottom: 16px;
  }

  body .w-100 {
    width: 100%;
  }

  body .max-w-100 {
    max-width: 100%;
  }

  body .h-100 {
    height: 100%;
  }

  body .h-24-px {
    height: 24px;
  }

  body .m-2 {
    margin: 12px;
  }

  body .mb-0 {
    margin-bottom: 0;
  }

  body .mb-1 {
    margin-bottom: 8px;
  }

  body .mb-2 {
    margin-bottom: 12px;
  }

  body .mb-22-px {
    margin-bottom: 22px;
  }

  body .mb-4 {
    margin-bottom: 24px;
  }

  body .ml-1 {
    margin-left: 6px;
  }

  body .ml-5-px {
    margin-left: 5px;
  }

  body .ml-8-px {
    margin-left: 8px;
  }

  body .ml-10-px {
    margin-left: 10px;
  }

  body .ml-2 {
    margin-left: 12px;
  }

  body .ml-3 {
    margin-left: 16px;
  }

  body .ml-4 {
    margin-left: 24px;
  }

  body .mr-0 {
    margin-right: 0;
  }

  body .mr-1 {
    margin-right: 6px;
  }

  body .mr-2 {
    margin-right: 12px;
  }

  body .mr-3 {
    margin-right: 16px;
  }

  body .mr-5 {
    margin-right: 40px;
  }

  body .mr-5-px {
    margin-right: 5px;
  }

  body .mr-8-px {
    margin-right: 8px;
  }

  body .mt-0 {
    margin-top: 0;
  }

  body .mt-2-px {
    margin-top: 2px;
  }

  body .mt-1 {
    margin-top: 6px;
  }

  body .mt-2 {
    margin-top: 12px;
  }

  body .mt-15-px {
    margin-top: 15px;
  }

  body .mt-16-px {
    margin-top: 16px;
  }

  body .mt-4 {
    margin-top: 24px;
  }

  body .mt-3 {
    margin-top: 18px;
  }

  body .mt-40-px {
    margin-top: 40px;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pl-4 {
    padding-left: 24px;
  }

  .pt-0 {
    padding-top: 0;
  }

  .p-0 {
    padding: 0;
  }

  body .p-0-imp {
    padding: 0;
  }

  body .p-15-px {
    padding: 15px;
  }

  body .p-50-px {
    padding: 50px;
  }

  body .p-40-px {
    padding: 40px;
  }

  body .p-v-50-px {
    padding: 50px 0;
  }

  body .p-v-40-px {
    padding: 40px 0;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .ml-auto {
    margin-left: auto;
  }

  body .m-0 {
    margin: 0;
  }

  body .pt-loading {
    padding: 12px 24px;
  }

  body .radius-2 {
    border-radius: 2px;
  }

  body .radius-6 {
    border-radius: 6px;
  }

  body .radius-16 {
    border-radius: 16px;
  }

  .horizontal-line {
    border-bottom: 1px solid #e8e8e8;
  }

  .hidden-visible {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }

  .word-break {
    white-space: break-spaces;
  }

  .word-break-word {
    word-break: break-word;
  }

  .multiple-line {
    white-space: pre-line;
  }

  .object-cover {
    object-fit: cover;
  }

  .ant-avatar-square {
    border-radius: 5px;
  }

  .cur-pointer {
    cursor: pointer;
  }

  body .border-none {
    border: none;
    box-shadow: unset;
    background-color: transparent;
  }

  body body .border-none:hover {
    background-color: transparent;
  }

  body .out-line-none {
    outline: none;
  }

  body .border-less .ant-modal-content {
    border-radius: 4px;
  }

  .object-fit-contain img {
    object-fit: contain;
  }

  body .btn-default-custom,
  body .btn-default-custom.ant-btn[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: unset;
    outline: none;
  }

  body .btn-default-custom-no-shadow:after,
  body .btn-default-custom-no-shadow.ant-btn[disabled]:after {
    display: none;
  }

  .break-line-text {
    white-space: pre-line;
    word-break: break-all;
  }

  body .btn {
    border-radius: 5px;
  }

  @media screen and (max-width: 768px) {
    .pl-0-mobile {
      padding-left: 0;
    }
    body .mt-2-mobile {
      margin-top: 12px;
    }
  }

  body .btn-line-main {
    border-radius: 5px;
    border: solid 1px #1890ff;
    background-color: #ffffff;
    height: 40px;
    min-width: 150px;

    color: #1890ff;
    font-size: 16px;
  }

  body .resize-none {
    resize: none;
  }

  body #nprogress .spinner {
    display: none;
  }

  body .divider-login {
    color: rgba(0, 0, 0, 0.06);
  }

  .divider-login .ant-divider-inner-text {
    color: #000;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .logo-freec {
    width: 51;
    height: 34px;
  }

  /* These code below will be remove later when refactor Header & Footer */

  .account-menubar-list .account-menubar__item {
    font-size: 14px;
    line-height: 22px;
    color: #595959;
    display: flex;
    padding: 9px 20px;
    align-items: center;
  }

  .account-menubar-list .account-menubar__item .menu-item {
    font-size: 12px;
    color: #000000;
    margin-right: 17px;
  }

  .account-menubar-list .account-menubar__item:hover,
  .account-menubar-list .account-menubar__item.active {
    background-color: #e6f7ff;
  }

  img.lang-flag {
    height: 20px;
    margin-right: 5px;
  }

  .ant-modal-root .full-screen-modal .ant-modal-body {
    padding: 0;
  }

  .ant-modal-root .full-screen-modal .ant-modal-content {
    background: #f6f8fc;
    min-height: 100vh;
    border-radius: 6px;
    padding-bottom: 30px;
  }

  .white-card {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 24px;
  }

  .icon-comment {
    color: #595959;
    margin-left: 4px;
    font-size: 16px;
  }

  .btn-link {
    color: #000;
    cursor: pointer;
  }

  .react-pdf__Page__annotations,
  .react-pdf__Page__textContent {
    display: none;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  .btn-light-main {
    padding: 4px 16px;
    border-radius: 5px;
    background-color: #e6f7ff;
    border: 0 none;

    font-weight: 500;
    text-align: center;
    color: #1890ff;
  }
  .btn-light-main:hover,
  .btn-light-main:active,
  .btn-light-main:link {
    background-color: #1890ff;
    color: #fff;
  }

  body .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px;
  }

  .ql-indent-1 {
    margin-left: 40px;
    padding-right: 10px;
  }
  .ql-indent-2 {
    margin-left: 55px;
    padding-right: 10px;
  }
  .ql-indent-3 {
    margin-left: 70px;
    padding-right: 10px;
  }
  .ql-indent-4 {
    margin-left: 85px;
    padding-right: 10px;
  }
  .ql-indent-5 {
    margin-left: 100px;
    padding-right: 10px;
  }
  .ql-indent-6 {
    margin-left: 115px;
    padding-right: 10px;
  }
  .ql-indent-7 {
    margin-left: 130px;
    padding-right: 10px;
  }
  .ql-indent-8 {
    margin-left: 145px;
    padding-right: 10px;
  }
  .ql-indent-9 {
    margin-left: 160px;
    padding-right: 10px;
  }
`
