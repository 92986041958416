import queryString from 'query-string'
import { LanguagesProficiencyType, LanguageType } from '../type/Common'

import { PAGE_ITEMS } from './Constant'
import {
  JOB_STATUS_EXPIRED,
  ENUM_JOB_FREEC_STATUS_NUMBER,
  JOB_STATUS_REJECTED,
  ENUM_JOB_STATUS,
  JOB_STATUS_PUBLISH,
  JOB_STATUS_REVIEWING,
  JOB_STATUS_CLOSED,
  JOB_STATUS_FREEZE
} from './Enum'

export const formatCurrency = (amount) => {
  const value = parseInt(amount).toString()
  return value.split(/(?=(?:\d{3})+(?:\.|$))/g).join(',')
}

export const getIsServer = (): boolean => typeof window === 'undefined'
export function languageCode2Name(code: string): string {
  return { vi: 'VN', en: 'EN', jp: 'JP' }[code]
}
export function stripHTML(originalString: string): string {
  return originalString.replace(/(<([^>]+)>)/gi, '')
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getUserIndexPage(userType: number): string {
  // const userCode = { 0: 'company', 1: 'candidate' }[userType] || 'anonymos'
  // const meta = config.security.role_metas.find((meta) => meta.role === userCode)
  return '/'
}
const toSEOString = (text: string) => text.toLowerCase().replace(/\s+/g, ' ').replace(/&/g, '').trim().replace(/\s/g, '+')
const fromSEOString = (text: string) => {
  return text.toLowerCase().replace(/\+/g, ' ')
}
export function toSEOStringFromQuery(params?: string | string[]): string {
  return params ? (typeof params === 'string' ? toSEOString(params) : toSEOString(params[0])) : undefined
}
export function toStringFromSE0Query(params?: string | string[]): string {
  return params ? (typeof params === 'string' ? fromSEOString(params) : fromSEOString(params[0])) : undefined
}
export function toStringFromQuery(params?: string | string[]): string {
  return params ? (typeof params === 'string' ? params : params[0]) : undefined
}
export function toIntFromQuery(params?: string | string[]): number {
  return params ? (typeof params === 'string' ? parseInt(params) : parseInt(params[0])) : undefined
}
export function toIntArrayFromQuery(params: string[]): number[] {
  return params ? (typeof params === 'string' ? [parseInt(params)] : params.map((s) => parseInt(s))) : []
}
export function removeEmptyProp(params: object): object {
  return Object.keys(params).reduce((final, key) => ({ ...final, ...(params[key] !== undefined ? { [key]: params[key] } : {}) }), {})
}
export function deserializeSEOParams(params: object): any {
  return Object.keys(params).reduce(
    (final, key) => ({ ...final, [key]: typeof params[key] === 'string' ? fromSEOString(params[key]) : params[key] }),
    {}
  )
}
export function escapedParams(params: object): any {
  return Object.keys(params).reduce(
    (final, key) => ({ ...final, [key]: typeof params[key] === 'string' ? encodeURIComponent(params[key]) : params[key] }),
    {}
  )
}
export function descapedParams(params: object): any {
  return Object.keys(params).reduce(
    (final, key) => ({ ...final, [key]: typeof params[key] === 'string' ? decodeURIComponent(params[key]) : params[key] }),
    {}
  )
}
export function removeEmptySpaceProp(params: object): object {
  return Object.keys(params).reduce((final, key) => ({ ...final, ...(params[key] !== '' ? { [key]: params[key] } : {}) }), {})
}
export function parsePramsToQueryString(params: object): string {
  const final = removeEmptyProp(params)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return queryString.stringify(final, { arrayFormat: 'bracket', decode: false, encode: false })
}
function nomarlizeQueryName(key: string) {
  return key.match(/\[\]/) ? key.replace('[]', '') : key
}
export function nomarlizeQuery(query: object): any {
  return Object.keys(query).reduce((final, key) => ({ ...final, [nomarlizeQueryName(key)]: query[key] }), {})
}
export const EmplymentList = [
  { id: 0, name: 'Full time', name_vn: 'Toàn thời gian' },
  { id: 1, name: 'Part time', name_vn: 'Bán thời gian' },
  { id: 2, name: 'Internship', name_vn: 'Thực tập sinh' },
  { id: 5, name: 'Freelancer', name_vn: 'Làm việc tự do' }
]
export const EmployerTypeMap = EmplymentList.reduce((result, lang) => ({ ...result, [lang.id]: lang.name }), {})

export function employmentText(type: number, language: string) {
  const temp = {
    0: { name: 'Full time', name_vn: 'Toàn thời gian' },
    1: { name: 'Part time', name_vn: 'Bán thời gian' },
    2: { name: 'Internship', name_vn: 'Thực tập sinh' },
    5: { name: 'Freelancer', name_vn: 'Làm việc tự do' },
    9: { name: 'Work remote', name_vn: 'Làm việc từ xa' },
    6: { name: 'Hybrid work', name_vn: 'Làm việc hybrid' }
  }[type]
  return temp ? (language === 'en' ? temp.name : temp?.name_vn || 'unknown') : 'unknown'
}
export function nomarlizeNotNullProperties(args: object): any {
  return Object.keys(args).reduce((result, key) => (args[key] ? { ...result, [key]: args[key] } : result), {})
}
export const ListDegree = [
  { id: 0, name: 'Diploma', name_vn: 'Bằng Cao đẳng' },
  { id: 1, name: 'Bachelor', name_vn: 'Bằng Cử nhân' },
  { id: 2, name: 'Master', name_vn: 'Bằng Thạc sĩ' },
  { id: 3, name: 'Doctoral', name_vn: 'Bằng Tiến sĩ' },
  { id: 4, name: 'Online', name_vn: 'Bằng đào tạo trực tuyến/từ xa' }
]

export const LanguagesProficiencys: LanguagesProficiencyType[] = [
  { name: 'Basic', name_vn: 'Cơ bản', id: 0 },
  { name: 'Intermediate', name_vn: 'Trung cấp', id: 1 },
  { name: 'Advanced', name_vn: 'Cao cấp', id: 2 },
  { name: 'Expert', name_vn: 'Chuyên gia', id: 3 }
]

export const languageList: LanguageType[] = [
  { id: 0, name: 'English', name_vn: 'Tiếng Anh' },
  { id: 1, name: 'Japanese', name_vn: 'Tiếng Nhật' },
  { id: 2, name: 'Korean', name_vn: 'Tiếng Hàn' },
  { id: 3, name: 'French', name_vn: 'Tiếng Pháp' },
  { id: 4, name: 'Chinese', name_vn: 'Tiếng Trung' },
  { id: 5, name: 'Thai', name_vn: 'Tiếng Thái' },
  { id: 6, name: 'Spanish', name_vn: 'Tiếng Tây Ban Nha' },
  { id: 7, name: 'Russian', name_vn: 'Tiếng Nga' },
  { id: 8, name: 'Italian', name_vn: 'Tiếng Ý' },
  { id: 9, name: 'Hindi', name_vn: 'Tiếng Ấn Độ' },
  { id: 10, name: 'German', name_vn: 'Tiếng Đức' }
]

export function toPagingAPI(URL: string, query?: any, page?: number, pageItems?: number): string {
  const finalQuery = query ? { ...query, items: pageItems || PAGE_ITEMS } : { items: pageItems || PAGE_ITEMS }
  return `${URL}${finalQuery ? `?${parsePramsToQueryString(page ? { ...finalQuery, page } : finalQuery)}` : ''}`
}

export function jobStatusFromFreeCStatusAndStatus(freec_status: number, status: number): string {
  // console.log({ freec_status })
  // console.log({ status })
  // console.log('ENUM_JOB_STATUS.closed', ENUM_JOB_STATUS.closed)
  // console.log('ENUM_JOB_STATUS.rejected', ENUM_JOB_FREEC_STATUS_NUMBER.rejected)
  if (status === ENUM_JOB_STATUS.closed && freec_status !== ENUM_JOB_FREEC_STATUS_NUMBER.rejected) {
    return JOB_STATUS_CLOSED
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.approved && status === ENUM_JOB_STATUS.published) {
    return JOB_STATUS_PUBLISH
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.pending && status === ENUM_JOB_STATUS.published) {
    return JOB_STATUS_REVIEWING
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.expired) {
    return JOB_STATUS_EXPIRED
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.rejected) {
    return JOB_STATUS_REJECTED
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.freeze && status === ENUM_JOB_STATUS.freeze) {
    return JOB_STATUS_FREEZE
  }
}

export function jobOrderStatusFromFreeCStatusAndStatus(freec_status: number, status: number): string {
  if (status === ENUM_JOB_STATUS.closed && freec_status !== ENUM_JOB_FREEC_STATUS_NUMBER.rejected) {
    return JOB_STATUS_CLOSED
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.approved && status === ENUM_JOB_STATUS.published) {
    return JOB_STATUS_PUBLISH
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.pending && status === ENUM_JOB_STATUS.published) {
    return JOB_STATUS_REVIEWING
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.expired) {
    return JOB_STATUS_EXPIRED
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.rejected) {
    return JOB_STATUS_REJECTED
  }

  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.freeze && status === ENUM_JOB_STATUS.freeze) {
    return JOB_STATUS_FREEZE
  }
}

export function isHTML(content: string): boolean {
  if (content && content.match('</[^>]+>')) {
    return true
  }
  return false
}

export function createIdSlug(slug: string, id: number | string): string {
  return `${slug}-i${id}`
}

export function createNewCompanyIdSlug(slug: string, id: number | string): string {
  return `${slug}-fi${id}`
}

export function removeAccents(str: string) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}

export function nonAccentVietnamese(str) {
  if (!str || typeof str !== 'string') {
    return ''
  }
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  return str
}

export function toStringArray(params: string | string[]): string[] {
  return typeof params === 'string' ? [params] : params
}

export function truncateLongString(value: string, maxCharacters?: number) {
  return value.length > (maxCharacters ? maxCharacters : 30) ? value.substring(0, maxCharacters ? maxCharacters : 30) + '...' : value
}

export function getSearchParam(params) {
  if (typeof window !== 'undefined') {
    const getSearchParamFromUrl = document.location.search.substring(1)
    const searchParams = new URLSearchParams(getSearchParamFromUrl)
    return searchParams.get(params)
  }

  return ''
}
