export const RESOURCE_IMAGE_FREEC_LOGO = '/img/logo_admin.svg'
export const RESOURCE_IMAGE_EN_FLAG = '/img/en_flag.svg'
export const RESOURCE_IMAGE_VN_FLAG = '/img/vn_flag.svg'

export const COOKIES_NAME_LANGUAGE = 'X_LANGUAGE'

export const DEFAULT_LOCALE = 'vi'

export const PATTERN_VALIDATION_UNICODE = /^[aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆfFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTuUùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ\s]*$/
export const USER_TYPE_COMPANY = 0
export const MOMENT_DATE_FORMAT_PARTTERN = 'DD/MM/YYYY'
export const MOMENT_DAY_MONTH_FORMAT_PARTTERN = 'DD/MM/YYYY'
export const MOMENT_DATE_DDD_MM_YYYY = 'DD MMM YYYY'

export const PAGE_ITEMS = 10
export const THUMBNAIL_JOB_DETAIL = '/img/search_job.jpg'
export const CV_MAX_SIZE_MB = 5
